import { Meta, MetaDataConfig } from '../types/customPage';

export function combineCustomPageMetaData(metaData: MetaDataConfig, path: string): Meta[] {
  let defaultMeta: Meta[] = metaData['default'] || [];
  const customRouteMeta: Meta[] = metaData[path] || [];
  const overWrites = customRouteMeta.map((m) => m.hid);

  defaultMeta = defaultMeta.filter((meta) => {
    return !overWrites.includes(meta.hid);
  });

  return [...customRouteMeta, ...defaultMeta];
}

export function isDefaultCustomPage(CUSTOM_PAGE_FOLDER_NAME: string): boolean {
  const defaultCustomPageFolderName = 'portal-custom-page-template';
  return defaultCustomPageFolderName === CUSTOM_PAGE_FOLDER_NAME || !CUSTOM_PAGE_FOLDER_NAME;
}

export function getLocalMetaDataUrl(locale: string) {
  const path = process.env.DEV_PATH;
  return `${path}/api/custom-page-meta?locale=${locale}`;
}
