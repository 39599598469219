
import Page from '@root/common/base/Page';
import CustomPageSkeletonLoader from '@root/modules/custom-page/components/CustomPageSkeletonLoader.vue';
import { isDefaultCustomPage } from '@root/modules/custom-page/utils/customPageMetaDataUtils';

export default Page.extend({
  components: {
    CustomPageSkeletonLoader,
  },
  props: {
    setup: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      customPage: null,
      isCustomPageLoaded: false,
    };
  },
  async beforeMount() {
    try {
      // Load when window is present in the client (custom page rendering logic depends on window object)
      const loader = () => import('../utils/render/renderCustomPage');
      const { renderCustomPage } = await loader();

      const { query } = this.$route;
      const src = this.setup?.src || null;
      const isProd = process.env.NODE_ENV === 'production';
      const previewUrl = query.preview ? this.setup.previewSrc : '';
      const defaultCustomPage = isDefaultCustomPage(this.$config.CUSTOM_PAGE_FOLDER_NAME);

      if (isProd && !src) {
        this.handlePageError({});
        return;
      }

      const useLocalProject = !defaultCustomPage && !isProd && !previewUrl;
      const componentToRender = await renderCustomPage({
        url: previewUrl || src,
        useLocalProject,
      });

      this.customPage = componentToRender;
    } catch (e) {
      this.handlePageError(e);
    }
  },
});
