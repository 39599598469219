
import Vue from 'vue';
import SkeletonLoader from '@root/common/components/base/loader/SkeletonLoader.vue';

export default Vue.extend({
  components: {
    SkeletonLoader,
  },
  props: {
    renderBanner: {
      type: Boolean,
      default: true,
      required: false,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    enableAd(): boolean {
      return this.renderBanner && !this.$store.getters['adForm/getDisabledAdsList']?.includes('tower');
    },
  },
});
