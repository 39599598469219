
// This vue file is used for legacy custom page projects
// It can be removed when custom pages use the new solution for every project

import { PropType } from 'vue';
import Page from '@root/common/base/Page';
import { loadScripts, executeScript } from '@root/common/utils/scriptLoader';

interface Container {
  type?: string;
  title: string;
  src: string;
  html?: string;
  script?: string;
}

export default Page.extend({
  props: {
    container: {
      type: Object as PropType<Container | null>,
      required: true,
    },
  },
  data() {
    return {
      pageBottomNavigation: null,
    };
  },
  beforeMount() {
    if (this.container) {
      this.getCustomPageData();
    }
  },
  methods: {
    getCustomPageData() {
      if (!this.container) {
        return false;
      }

      if (this.container.src) {
        loadScripts(this.container.src).then(() => {
          if (this.container!.script) {
            executeScript(this.container!.script);
          }
        });
      } else if (this.container.script) {
        executeScript(this.container.script);
      }
    },
  },
});
