
import Icon from '@root/modules/weather/components/Icon.vue';
import getLocationUrl from '@root/modules/weather/utils/getLocationUrl';
import { toDailyForecast, weatherIconParser } from '@root/modules/weather/utils/weatherParser';

interface Data {
  activeDay: string | null;
}

interface Props {
  forecastData: any;
}

interface Computed {
  dailyForecastData: Array<any>;
  days: any;
}

interface Methods {
  getLocation: (location: any) => string;
  getAverageTemp: (temp: any) => any;
  weatherIconParser: typeof weatherIconParser;
}

export default getLocationUrl.extend<Data, Methods, Computed, Props>({
  components: {
    Icon,
  },
  props: {
    forecastData: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      activeDay: null,
      type: null,
    };
  },
  computed: {
    dailyForecastData() {
      const dailyForecastData: Array<any> = [];

      this.forecastData.forEach((item: any) => {
        dailyForecastData.push(item.type ? item : { ...item, dailyForecast: toDailyForecast(item.dailyForecast, 5) });
      });

      return dailyForecastData;
    },
    days() {
      const days: string[] = [];
      const first = this.dailyForecastData.find((item: any) => !item.type);

      first.dailyForecast.forEach((day: any) => {
        days.push(day.date);
      });

      return days;
    },
  },
  methods: {
    getLocation(location: any) {
      return this.getLocationUrl(location, false);
    },
    getAverageTemp(t: any) {
      const values = [];
      values.push(t.data.next_6_hours.details.air_temperature_min, t.data.next_6_hours.details.air_temperature_max);

      return values.reduce((a: any, b: any) => a + b) / values.length;
    },
    weatherIconParser,
  },
});
