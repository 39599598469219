
import Page from '@root/common/base/Page';
import Profile from '@root/modules/customer/components/Profile.vue';
import ResetPassword from '@root/modules/customer/components/ResetPassword.vue';
import getPageAdsSettings from '@root/modules/customer/utils/getPageAdsSettings';

const slugMapping = {
  profilis: 'Profile',
  profile: 'Profile',
  profil: 'Profile',
  'atstatyti-slaptazodi': 'ResetPassword',
};

interface Data {
  componentType: string;
}

export default Page.extend<Data, unknown, unknown, unknown>({
  components: {
    Profile,
    ResetPassword,
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();
    this.resetPageHeaderBottomNavigation();

    next();
  },
  data() {
    return {
      componentType: '',
    };
  },
  async beforeMount() {
    const { component } = this.$channelConfig('page').client;
    this.setPageHeaderBottomNavigation(component?.header?.navigation?.bottom);

    this.$store.commit('adForm/setPageAdsSettings', getPageAdsSettings({ $channelConfig: this.$channelConfig }));
  },
  async mounted() {
    const { params } = this.$route;

    const slug = params.slug || '';

    try {
      this.componentType = slugMapping[slug as keyof typeof slugMapping];
      if (!this.componentType) {
        throw new Error('Page not found');
      }
    } catch (e) {
      this.handlePageError(e);
    }
  },
});
