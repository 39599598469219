
import Page from '@root/common/base/Page';
import CustomPageContent from '@root/modules/custom-page/components/CustomPageContent.vue';
import CustomPageAppLegacy from '@root/modules/custom-page/components/CustomPageAppLegacy.vue';
import WeatherCustomPageContent from '@root/modules/weather/components/WeatherCustomPageContent.vue';
import getCustomPageAdsSettings from '@root/modules/custom-page/utils/getCustomPageAdsSettings';
import { Meta, MetaDataConfig } from '@root/modules/custom-page/types/customPage';
import { combineCustomPageMetaData, isDefaultCustomPage, getLocalMetaDataUrl } from '@root/modules/custom-page/utils/customPageMetaDataUtils';
import Service from '@root/common/base/BaseService';
import { cxenseMeta, ImageData, zlickMeta } from '@root/common/types/meta';

interface Container {
  type?: string;
  title: string;
  src: string;
  html?: string;
  script?: string;
  metaSrc?: string;
  metaData?: Meta[];
}

interface Data {
  pageBottomNavigation: Record<string, any> | null;
  container: Container | null;
  metaData: Meta[];
}

export default Page.extend<Data, unknown, unknown, unknown>({
  components: {
    CustomPageContent,
    CustomPageAppLegacy,
    WeatherCustomPageContent,
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();
    this.resetPageHeaderBottomNavigation();

    next();
  },
  data() {
    return {
      pageBottomNavigation: null,
      container: null,
      metaData: [],
    };
  },
  head() {
    const meta = this.getPageMeta();

    const metaData = (this.metaData as (cxenseMeta | zlickMeta)[]) || [];
    let title = this.$channelConfig('meta').base.title;

    function contentValue(metaData: (cxenseMeta | zlickMeta)[], key: string, fallback: string): string {
      return (
        metaData
          .filter((m) => m.hid === key)
          .map((m) => m.content)
          .toString() || fallback
      );
    }

    if (metaData && metaData.length > 0) {
      title = contentValue(metaData, 'title', title);
      if (title) {
        meta.setTitle({ title });
      }

      const description = contentValue(metaData, 'description', '');
      if (description) {
        meta.description = description;
        meta.ogDescription = description;
      }

      const imgSrc = contentValue(metaData, 'og_image', '');
      if (imgSrc) {
        const width = contentValue(metaData, 'og_image_width', '1200');
        const height = contentValue(metaData, 'og_image_height', '628');
        const img: ImageData = {
          src: imgSrc,
          width: parseInt(width),
          height: parseInt(height),
        };
        meta.imageData = img;
      }
    }

    return {
      title: meta.title,
      meta: meta.data,
      link: meta.link,
    };
  },
  watch: {
    container(containerData) {
      if (containerData && this.pageBottomNavigation) {
        this.setPageHeaderBottomNavigation(this.pageBottomNavigation);
      }
    },
  },
  async fetch() {
    const { path } = this.$route;
    const isProd = process.env.NODE_ENV === 'production';
    const { locale } = this.$channelConfig('settings');

    try {
      const match = this.$channelConfig('routes')?.filter((route: { path: string }) => path.includes(route.path));
      let container: Container | null = null;
      let pageData = null;

      if (match) {
        pageData = match[0];
        pageData.fullPath = this.$route.fullPath;
        container = pageData?.props?.container || null;
      }

      if (process.server) {
        try {
          let metaSrc = null;

          if (!isProd && !isDefaultCustomPage(this.$config.CUSTOM_PAGE_FOLDER_NAME)) {
            metaSrc = getLocalMetaDataUrl(locale);
          } else if (container && container.metaSrc) {
            metaSrc = container.metaSrc;
          }

          if (metaSrc) {
            const service = new Service();
            const apiProvider = service.createProvider('HTTP');

            const metaResponse = await apiProvider.request<MetaDataConfig>({
              url: metaSrc,
              method: 'get',
              timeout: 1000,
              // @ts-ignore
              // strictSSL: isProd,
            });

            this.metaData = combineCustomPageMetaData(metaResponse, path);
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (!container && isProd) {
        throw Error;
      }

      if (pageData) {
        const navigation = pageData?.components?.header?.navigation?.bottom;
        this.pageBottomNavigation = navigation;
        this.setPageAdsSettings(getCustomPageAdsSettings({ pageData }));
      }

      this.container = container;
    } catch (e) {
      this.handlePageError(e);
    }
  },
});
