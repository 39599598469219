
import Page from '@root/common/base/Page';
import WeatherService from '@root/modules/weather/services/Weather.service';
import TableLocations from '@root/modules/weather/components/table/TableLocations.vue';
import AdsLayout from '@root/common/components/layout/AdsLayout.vue';
import getFrontPageAdsSettings from '@root/modules/frontpage/utils/getFrontPageAdsSettings';
import isMobileView from '@root/common/utils/isMobileView';
import ListSkeletonLoader from '@root/common/components/base/loader/ListSkeletonLoader.vue';

interface Data {
  loading: boolean;
  container: Container;
  items: Array<Record<string, any>>;
  itemsLoaded: Array<Record<string, any>>;
  itemsLoading: boolean;
  meta: Record<string, any>;
  limit: number;
  start: number;
  weatherData: Array<Record<string, any>>;
  isMobileView: boolean;
}

interface Container {
  title?: string;
  html?: string;
  data?: Record<string, any>;
}

interface Methods {
  loadFirstItems: () => void;
  loadNextItems: () => void;
}

interface Computed {
  disabledAdsList: string[];
}

export default Page.extend<Data, Methods, Computed, unknown>({
  components: {
    TableLocations,
    AdsLayout,
    ListSkeletonLoader,
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();

    next();
  },
  data() {
    return {
      loading: true,
      container: {} as Container,
      items: [],
      itemsLoading: false,
      itemsLoaded: [],
      meta: {
        title: 'Delfi orai',
      },
      limit: 10,
      start: 0,
      weatherData: [],
      isMobileView: false,
    };
  },
  computed: {
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
  },
  async fetch() {
    const { path } = this.$route;

    try {
      const match = this.$channelConfig('routes')?.filter((route: { path: string }) => path.includes(route.path));

      let container: any = null;
      let pageData = null;
      // let weatherData: any = {};

      if (match) {
        pageData = match[0];
        container = pageData.props?.container;

        if (container.title) {
          this.meta.title = `${container.title} - ${this.meta.title}`;
        }

        if (container.data) {
          this.items = container.data;
        }

        this.setPageAdsSettings(getFrontPageAdsSettings({ $channelConfig: this.$channelConfig }));
      }
    } catch (e) {
      this.handlePageError(e);
    }
  },
  mounted() {
    this.isMobileView = isMobileView();

    this.loadFirstItems();
    window.addEventListener('scroll', this.loadNextItems);
  },
  methods: {
    async loadFirstItems() {
      if (!this.items.length) {
        return;
      }

      const items = this.items.slice(this.start, this.limit);
      const section = [];
      for (const item of items) {
        const weatherService = new WeatherService();
        if (!item.type) {
          const response = await weatherService.fetch({ placeId: item.placeId, hourlyTime: 100, dailyTime: 100 });
          section.push({ ...item, ...response });
        } else {
          section.push(item);
        }
      }

      this.weatherData = [...this.weatherData, ...section];
      this.start = this.start + this.limit;
      this.loading = false;
    },
    async loadNextItems() {
      const d = document.documentElement;
      const w = document.querySelector('.table-days') as HTMLElement;
      const userHasScrolled = window.pageYOffset > 100;
      const offset = window.pageYOffset + (d.clientHeight + 500);
      const height = w.offsetHeight;

      if (userHasScrolled && offset >= height && !this.loading) {
        this.loading = true;

        const items = this.items.slice(this.start, this.start + this.limit);
        if (items.length) {
          const section = [];

          for (const item of items) {
            const weatherService = new WeatherService();
            if (!item.type) {
              const response = await weatherService.fetch({ placeId: item.placeId, hourlyTime: 100, dailyTime: 100 });
              section.push({ ...item, ...response });
            } else {
              section.push(item);
            }
          }

          this.weatherData = [...this.weatherData, ...section];
          this.start = this.start + this.limit;
        }

        this.loading = false;
      }
    },
  },
  head() {
    const meta = this.getPageMeta();

    return {
      title: this.meta.title,
      meta: meta.data,
      link: meta.link,
    };
  },
});
