import { GetChannelConfigFunction } from '@root/libs/init-channel/types';

export default function getPageAdsSettings({ $channelConfig }: { $channelConfig: GetChannelConfigFunction }) {
  const { id } = $channelConfig('settings');
  const { adForm } = $channelConfig('ads').component;

  // Create keywords data
  const keywords = [`${id}`];
  const keyValue = [`www.delfi.lt:${id}`];

  // Load page ad placement settings
  const { component } = $channelConfig('page').client;

  const placements: Record<string, unknown> = component.adForm?.placements || adForm.placements || {};
  const disableAds = adForm.disableAds || [];

  return {
    mkw: keywords,
    mkv: keyValue,
    placements,
    disableAds,
  };
}
