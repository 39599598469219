import { Data as CustomPageData } from '../types/customPage';

export default function getCustomPageAdsSettings({ pageData }: { pageData: CustomPageData }) {
  // Create keywords data
  const keywords = [`customPage_${pageData.name}`];

  if (pageData.fullPath) {
    const parts = pageData.fullPath.substring(1).split('/');
    parts.forEach((part: string) => {
      keywords.push(`customPage_${part}`);
    });
  }

  // Create keyValues data
  const keyValue = [`${pageData.name}`];
  // Get page placements
  const placements = pageData.components?.adForm?.placements || {};
  // Get disabled ads
  const disableAds = pageData.components?.adForm?.disableAds || [];

  return {
    mkw: keywords,
    mkv: keyValue,
    placements,
    disableAds,
  };
}
