
import Vue from 'vue';

interface Data {
  containerSelector: string;
}

export default Vue.extend<Data, unknown, unknown, unknown>({
  data() {
    return {
      containerSelector: 'piano-component',
    };
  },
  mounted() {
    this.$store.dispatch('piano/showResetPassword', `#${this.containerSelector}`);
  },
});
