
import Vue from 'vue';

interface Data {
  containerSelector: string;
}

interface Computed {
  isLoggedIn: boolean;
}

export default Vue.extend<Data, Computed, unknown, unknown>({
  data() {
    return {
      containerSelector: 'piano-component',
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.piano.isLoggedIn;
    },
  },

  watch: {
    async isLoggedIn(value) {
      if (value) {
        this.$store.dispatch('piano/showAccount', {
          containerSelector: `#${this.containerSelector}`,
          displayMode: 'inline',
        });
      } else {
        await this.$store.dispatch('piano/showLoginForm', '#piano-login-form');
      }
    },
  },

  async mounted() {
    try {
      const shown = await this.$store.dispatch('piano/showAccount', {
        containerSelector: `#${this.containerSelector}`,
        displayMode: 'inline',
      });
      if (!shown) {
        this.$store.dispatch('piano/showLoginForm', '#piano-login-form');
      }
    } catch (e) {
      throw new Error('Something went wrong');
    }
  },
});
